import { useCrudErrorHandler } from 'common-v2/error-helpers/useCrudErrorHandler'
import { Language, useSendLoginMagicLinkMutation } from 'generated/tensor-api/generated-types'
import { YOU_HAVE_MAIL_V2_URL } from 'links'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'
import { Button } from 'ui-v2/components/Button'
import { ControlledTextField } from 'ui-v2/forms/ControlledTextField'
import { Form } from 'ui-v2/forms/Form'
import { TypesFromSchema, useForm } from 'ui-v2/forms/useForm'
import { validateEmail } from 'validation'
import * as Yup from 'yup'
import { Disclaimer } from './Disclaimer'
import { formContainerCx } from './LoginView.css'
import { Title } from './Title'

export const d = {
  title: 'auth:login-v2.title',
  emailSignIn: 'auth:login-v2.email-sign-in',
  emailLinkDisclaimer: 'auth:login-v2.email-link-disclaimer',
  required: 'validation:required',
}
export interface LoginViewProps {}
export const LoginView: FC<LoginViewProps> = () => {
  const router = useRouter()
  const { t, lang } = useTranslation()
  const handleCrudError = useCrudErrorHandler({ toastId: 'send-magic-link-error' })

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: validateEmail(t).required(t(d.required)),
      }),
    [t]
  )
  const defaultValues: TypesFromSchema<typeof validationSchema> = {
    email: '',
  }

  const {
    submitForm,
    control,
    formState: { isSubmitting },
  } = useForm({
    validationSchema,
    values: defaultValues,
  })

  const [sendMagicLink] = useSendLoginMagicLinkMutation({
    onCompleted: ({ user }) => {
      if (user.sendLoginMagicLink.success) {
        router.push({ pathname: YOU_HAVE_MAIL_V2_URL }, undefined, { locale: lang })
      } else handleCrudError({ message: 'Magic link request failed' })
    },
    onError: (err) => {
      handleCrudError(err)
    },
  })

  const handleSubmit = submitForm(async (values) => {
    await sendMagicLink({
      variables: {
        input: { email: values.email, language: lang === 'en' ? Language.En : Language.Jp },
      },
    })
  })

  return (
    <Form className={formContainerCx} onSubmit={handleSubmit}>
      <Title>{t(d.title)}</Title>
      <ControlledTextField
        size="lg"
        control={control}
        name="email"
        label={t(d.emailSignIn)}
        hideLabel
        inputMode="email"
      />
      <Button
        variant="solid"
        size="lg"
        color="primary"
        width="full"
        type="submit"
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
      >
        {t(d.emailSignIn)}
      </Button>
      <Disclaimer>{t(d.emailLinkDisclaimer)}</Disclaimer>
    </Form>
  )
}
